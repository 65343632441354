<template>
  <div class="pickup-orders">
    <query-form
      class="content_block"
      label-width="100px"
      size="small"
      label-position="right"
      @search="getList('init')"
      @reset="handleReset"
      :keepOpenedRow="2"
    >
      <el-form-item label="发货状态：" :span="24">
        <check-tag v-model="advancedForm.receivingSts" @change="() => getList('init')">
          <check-tag-item
            :value="i.value"
            :key="i.value"
            v-for="i in executionStatusGroup"
            :label="`${i.label}(${i.count || 0})`"
          ></check-tag-item>
        </check-tag>
      </el-form-item>
      <el-form-item label="订单编号：">
        <el-input
          v-model.trim="advancedForm.orderCode"
          clearable
          placeholder="请输入订单编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="客户抬头：">
        <el-input
          v-model.trim="advancedForm.unit"
          clearable
          placeholder="请输入客户抬头"
        ></el-input>
      </el-form-item>
      <el-form-item label="收货状态：">
        <el-select
          v-model.trim="advancedForm.collectState"
          clearable
          placeholder="请选择"
          @change="getList('init')"
        >
          <el-option
            v-for="item in receiveStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单状态：">
        <el-select
          v-model.trim="advancedForm.orderSts"
          clearable
          placeholder="请选择"
          @change="getList('init')"
        >
          <el-option
            v-for="item in orderStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开票状态：">
        <el-select
          v-model.trim="advancedForm.billingState"
          clearable
          placeholder="请选择"
          @change="getList('init')"
        >
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="(item, index) in invoiceStatusOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收款状态：">
        <el-select
          v-model.trim="advancedForm.payState"
          clearable
          placeholder="请选择"
          @change="getList('init')"
        >
        <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in collectionStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收货人：">
        <el-input
          v-model.trim="advancedForm.receiverName"
          clearable
          placeholder="请输入收货人"
        ></el-input>
      </el-form-item>
      <el-form-item label="收货人手机：">
        <el-input
          v-model.trim="advancedForm.receiverPhone"
          clearable
          placeholder="请输入收货人手机"
        ></el-input>
      </el-form-item>
      <el-form-item label="销售合同：">
        <el-input
          v-model.trim="advancedForm.pcCode"
          clearable
          placeholder="请输入销售合同"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注：">
        <el-input
          v-model.trim="advancedForm.remark"
          clearable
          placeholder="请输入销售合同"
        ></el-input>
      </el-form-item>
    </query-form>
    <TableWithPagination
      class="content_block"
      :columnList="columnList"
      :tableData="dataList"
      :pagination.sync="pagination"
      @size-change="getList"
      @current-change="getList"
      @sort-change="getList"
    />
  </div>
</template>
<script>
import TableWithPagination, {
  TablePaginationMixin,
} from '@/components/common/TableWithPagination';
import { queryPage, queryStateInfo } from '@/api/pickUpOrders';
import setTableCols from './configs';
import {
  executionStatusGroup,
  orderStatusOptions,
  deliveryStatusOptions,
  receiveStatusOptions,
  collectionStatusOptions,
  invoiceStatusOptions,
} from '@/model/pickUpOrders';

export default {
  name: 'pickup-orders',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  data () {
    return {
      columnList: setTableCols.call(this),
      dataList: [],
      advancedForm: {
        receivingSts: '',
        pcCode: '',
        billingState: '',
        payState: '',
        receiverName: '',
        receiverPhone: '',
        orderCode: '',
        unit: '',
        collectState: '',
        orderSts: '',
        remark: '',
      },
      executionStatusGroup,
      orderStatusOptions,
      deliveryStatusOptions,
      receiveStatusOptions,
      collectionStatusOptions,
      invoiceStatusOptions,
    };
  },
  created () {
    this.$store.dispatch('changeDefaultActive', this.$route.name);

    if (this.$route.query.status || this.$route.query.status === 0) {
      this.advancedForm.receivingSts = this.$route.query.status + '';
    }

    this.getList();
    this.getSaleContractCount();
  },
  methods: {
    getList (type) {
      if (type === 'init') {
        this.pagination = {
          total: 0,
          currentPage: 1,
          pageSize: 10,
        };
      }

      let { pageSize, currentPage: pageNum } = this.pagination;
      let orderBy = [
        {
          orderByProperty: 'lastUpdateTime',
          orderByType: 'DESC',
        },
      ];

      queryPage({ ...this.advancedForm, orderBy, pageSize, pageNum }).then(
        ({ body }) => {
          this.dataList = body.list;
          this.pagination.total = body.total;
        },
      );
    },
    getSaleContractCount () {
      queryStateInfo().then(({ body }) => {
        for (let v of this.executionStatusGroup) {
          v.count = body[v.en];
        }
      });
    },
    handleReset () {
      for (let key in this.advancedForm) {
        this.advancedForm[key] = '';
      }

      this.pagination = {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      };

      this.getList();
    },
    operate (type, row) {
      const path = type === 'detail' ? '/pickUpOrders/detail' : '/pickUpOrders/ship';

      this.$router.push({
        path,
        query: {
          orderCode: row.orderCode,
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.pickup-orders {
  /deep/ .contract-option {
    color: #02a7f0;
    cursor: pointer;
    padding: 0 0.25em;
  }

  .el-range-editor--small.el-input__inner {
    width: 100%;
  }
}
</style>
